require(['jquery'], function ($) {

    $('.product_pricetag').each(function () { $(this).text('$') });

    if ($("#mini-cart").length > 0) {
        $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
    }

    $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");
    if ($(".logo-wrapper").length > 0) {
        $('.logo-wrapper').append("<div class='show-below-960 right nav-search'>" + $(".search-wrapper").html() + "</div>");
        if ($("#mini-cart").length > 0) {
            $('.logo-wrapper').prepend("<div class='show-below-960 right nav-cart'>" + $("#mini-cart").html() + "</div>");
        }
    }

    if ($(".variant-option").length > 0) {
        if ($(".variant-option .swatches-con .swatch img").length > 0) {
            $(".variant-option .swatches-con").css('display', 'block');
        }
    }

    $('.page-type-home .main-banner-container .banner-caption').before('<div class="main-banner-overlay"></div>');
    $('.footer-bottom .section.clearer').append('<div class="item item-right"><img src="https://cdn.staging-mysagestore.com/fd5237bc62083a5d35cfdf6634ccb54b/contents/ckfinder/images/registered.png"></div><div class="item item-right"><img src="https://cdn.staging-mysagestore.com/fd5237bc62083a5d35cfdf6634ccb54b/contents/ckfinder/images/women-owned.png"></div><div class="item item-right"><img src="https://cdn.staging-mysagestore.com/fd5237bc62083a5d35cfdf6634ccb54b/contents/ckfinder/images/hub.png"></div>');

    $(window).on('resize', onResize);

    $(document).ready(function () {
        onResize();
    });

    var onResize = function () {
        if ($(window).width() < 960) {
            $(".nav-search .form-search .button.button-search").on("click", function (e) {
                if (!$(".nav-search").hasClass("fly-out")) {
                    e.preventDefault();
                    $(".nav-search").addClass("fly-out");
                    $(".nav-search .form-search .input-text").focus();
                    if (!$(".nav-search .form-search .button.button-close").length) $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
                }
            });
        }
    };

    $(document).on('click', '.nav-search .button-close', function (e) {
        e.preventDefault();
        $(".nav-search").removeClass("fly-out");
    });

    $('.sidebar-category .block .block-title, .sidebar-filter .block .block-title').click(function () {
        $(this).toggleClass('open');
    });

    if ($('.page-type-category').length) {
        setTimeout(() => {
            const itemHeight = $('ul#listing-ul li.item:first-of-type').css('height');
            $('body').append('<style>.category-products-grid.hover-effect .item:hover{height:' + itemHeight + ' !important;}</style>');
        }, 1000);

    }

    $('.grey-back.no-gutter.container-size .nested-container > .grid-container').addClass('container');

    $('div.multi-tab-container div.multi-tab:eq(0)').addClass('active');
    const firstTabClassNames = $('div.multi-tab-container div.multi-tab:eq(0)')[0].className;
    const firstTabDashIndex = firstTabClassNames.indexOf('--') + 2;
    const firstTabDashIndex2 = firstTabClassNames.lastIndexOf('--');
    const firstTabclass = firstTabClassNames.substring(firstTabDashIndex, firstTabDashIndex2);

    $('div.multi-content-container div.multi-content.--' + firstTabclass + '--').show();
    $('div.multi-content-container.vertical-padding-container div.multi-content.--' + firstTabclass + '--').parent().addClass('vertical-padding');


    $('.multi-tab-container .multi-tab').click(function () {
        const className = $(this)[0].className;
        const dashIndex = className.indexOf('--') + 2;
        const dashIndex2 = className.lastIndexOf('--');
        const fClassName = className.substring(dashIndex, dashIndex2);

        $('.multi-tab-container .multi-tab').removeClass('active');
        $(this).toggleClass('active');

        $('.multi-content-container .multi-content').hide();
        $('.multi-content-container.vertical-padding-container .multi-content').parent().removeClass('vertical-padding');
        $('.multi-content-container .multi-content.--' + fClassName + '--').show();
        $('.multi-content-container.vertical-padding-container .multi-content.--' + fClassName + '--').parent().addClass('vertical-padding');
    });

    
});